<template>
  <section class="content">
    <table class="table table-hover" ref="tblkelas">
      <thead>
        <tr>
          <th>NAMA KELAS</th>
          <th>PROGRAM</th>
          <th>RUANG</th>
          <th>DETAIL</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Nama Kelas</label>
                  <input
                    id="kelas"
                    class="form-control"
                    v-model="form.kelas"
                    type="text"
                    required="required"
                    name="kelas"
                  />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Ruangan</label>
                    <v-select :options="optRuang" v-model="form.ruang_id" :reduce="opt => opt.id" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  components: {
      vSelect,
  },
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Kelas",
      form: {
        kelas: "",
        ruang_id: ""
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch('/akademik/data_induk_kelas/ruang')
      .then(res => {
          if (res.status === 201) {

          } else if (res.status === 400) {}
          return res.json();
      })
      .then(js => {
          this.optRuang = js.data;
      })
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
        this.$router.push('/data-induk-kelas-detail/'+e.srcElement.getAttribute('data-id')+'/'+e.srcElement.getAttribute('data-branch'));
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/akademik/data_induk_kelas";
      if (this.method == "PUT")
        urlSubmit = "/akademik/data_induk_kelas/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/data_induk_kelas",
      columns: [
        { data: "kelas" },
        { data: "program",
        render: function (data, type, row, meta) {
            let act = ``;
            for(var x in data)
            {
            var program = data[x];
            act += `
                <h5 style="display:inline-block;"><span class="badge badge-primary">${program}</span></h5>
            `;
            }
            return act;
        }, },
        { data: "nama_ruang" },
        { data: "program" },
      ],
      filterBy: [0],
      rowCallback: function (row, data) {
        // if (data.active == "true")
        //   $("td:eq(3)", row).html(
        //     '<span class="badge badge-success">Active</span>'
        //   );
        // else if (data.active == "false")
        //   $("td:eq(3)", row).html(
        //     '<span class="badge badge-warning">Not Active</span>'
        //   );
        $('td:eq(3)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id+'" data-branch="'+data.branch_id+'">Data Siswa</a>');
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Kelas";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          //jika active == 0 return false, jika active == 1 return true
          self.form.active = evt.data.active == "true" ? true : false; //operator ternary
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Kelas";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/akademik/data_induk_kelas/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
